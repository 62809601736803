@media (max-width:899px) {
    .panel-part{
        text-align: center;
    }    
}

h2{
    color: var(--pc-dg-);
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
}

p{
    color: var(--pc-vdg-);
    font-size: 1.25rem;
    margin-top: 0.5rem;
    font-weight: 500;
}